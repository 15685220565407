import { useState } from 'react';
import TASKS_TEXTS from '../../../../legends/tasks.json';
import ATTACKS_TEXTS from '../../../../legends/attacks.json';
import TYPES_TEXTS from '../../../../legends/types.json';
import QUEST_TEXTS from '../../../../legends/quests.json';
import translate from "../../../../legends";

function Task({ type, targets, attack, weakness, quest, bonus, count, newValue, i, incrementClick, decrementClick, locale }) {
  const taskName = ((TASKS_TEXTS[type] && TASKS_TEXTS[type][locale]) || "")
    .replace(/\{attack\}/g, (ATTACKS_TEXTS[attack] && ATTACKS_TEXTS[attack][locale]) || attack)
    .replace(/\{weakness\}/g, (TYPES_TEXTS[weakness] && TYPES_TEXTS[weakness][locale]) || weakness)
    .replace(/\{quest\}/g, (QUEST_TEXTS[quest] && (QUEST_TEXTS[quest][locale] || QUEST_TEXTS[quest].en)) || quest)
    .replace(/\{quest_no\}/g, quest);
  const [showMenu, setShowMenu] = useState(false);

  const openMenu = () => {
    if(!showMenu) {
      setShowMenu(true);

      const closeMenu = () => {
        setShowMenu(false);
        // TODO : Should be removed if component is unmount
        window.document.removeEventListener("touchend", closeMenu);
      };

      setTimeout(() => window.document.addEventListener("touchend", closeMenu), 1);
    }
  };

  const hasNewValue = !!(newValue || newValue === 0);

  return (
    <div className={"Tasks-task" + (showMenu ? " Tasks-task-withmenu" : "")}>
      <div className={"Tasks-task-value" + (bonus ? " Tasks-task-doubled" : "")}></div>
      <div className={"Tasks-task-name" + (taskName.length > 30 ? (taskName.length > 45 ? " Tasks-task-verylongname" : " Tasks-task-longname") : "")}>{taskName}</div>
      <div className="Tasks-task-values">
        <div className={"Tasks-task-count" + (hasNewValue ? " Tasks-task-count-withnewvalue" : "")} onTouchEnd={hasNewValue ? undefined : (e) => { e.preventDefault(); openMenu(); }} onClick={hasNewValue ? undefined : () => incrementClick(i)} onContextMenu={hasNewValue ? undefined : (e) => { e.preventDefault(); decrementClick(i); }} title={translate("Left-click to increase, right-click to decrease.", locale)}>
          <span className={"Tasks-task-count-value" + (hasNewValue ? " Tasks-task-count-oldValue" : "")}>{count}</span>
          {hasNewValue && (
            <span className="Tasks-task-count-newValue">{newValue}</span>
          )}
          {showMenu && (
            <div className="Tasks-task-count-menu">
              <div className="Tasks-task-count-menu-button" onTouchEnd={(e) => e.stopPropagation()} onClick={(e) => { e.stopPropagation(); decrementClick(i); }}>-</div>
              <div className="Tasks-task-count-menu-button" onTouchEnd={(e) => e.stopPropagation()} onClick={(e) => { e.stopPropagation(); incrementClick(i); }}>+</div>
            </div>
          )}
        </div>
        <div className="Tasks-task-targets">
          {targets.map((t, i) => <div key={i} className={"Tasks-task-target" + (t <= count || (newValue && t <= newValue)? " Tasks-task-target-done" : "") + (t > count && (newValue && t <= newValue)? " Tasks-task-target-done-new" : "")}>{t}</div>)}
        </div>
      </div>
    </div>
  );
}

function Tasks({ selected, newValues, counts, setCounts, acceptValues, rejectValues, locale }) {
  const taskCounts = counts[selected.name.en] || selected.tasks.map(_ => 0);

  const incrementClick = (id) => {
    const newCounts = Object.assign({}, counts);
    newCounts[selected.name.en] = (counts[selected.name.en] || selected.tasks.map(_ => 0)).map((x, i) => i === id ? Math.min(x + 1, 999) : x);
    setCounts(newCounts);
  }

  const decrementClick = (id) => {
    const newCounts = Object.assign({}, counts);
    newCounts[selected.name.en] = (counts[selected.name.en] || selected.tasks.map(_ => 0)).map((x, i) => i === id ? Math.max(x - 1, 0) : x);
    setCounts(newCounts);
  }

  return (
    <div className="Tasks">
      <div className="Tasks-header">
        <div className="Tasks-name">{translate("Research Tasks for", locale)} {selected.name[locale] || selected.name.en}</div>
      </div>
      {selected.tasks.map((t, i) => <Task key={i} {...t} count={taskCounts[i] || 0} newValue={newValues && newValues[i].value} i={i} incrementClick={incrementClick} decrementClick={decrementClick} locale={locale} />)}
      {!!(newValues && newValues.length) && (
        <div className="Tasks-newvalues">
          <div className="Tasks-newvalues-label">{translate("Accept new values ?", locale)}</div>
          <div className="Tasks-newvalues-actions">
            <div className="Tasks-newvalues-button" onClick={acceptValues}>{translate("Yes", locale)}</div>
            <div className="Tasks-newvalues-button" onClick={rejectValues}>{translate("No", locale)}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Tasks;
