import { useState, useEffect } from 'react';
import Camera from '../../../camera';
import parsePicture from '../../../../helpers/parse-picture';
import RotatingMessage from '../../../../helpers/rotating-message.js';
import translate from '../../../../legends';

import './index.css';
import Infos from './infos';
import Tasks from './tasks';
import Locations from "./locations";
import Favorites from "./favorites";
import infosActive from './images/infos_active.png';
import infosInactive from './images/infos_inactive.png';
import tasksActive from './images/tasks_active.png';
import tasksInactive from './images/tasks_inactive.png';
import mapActive from './images/map_active.png';
import mapInactive from './images/map_inactive.png';
import cameraInactive from './images/camera_inactive.png';
import favoritesActive from './images/favorites_active.png';
import favoritesInactive from './images/favorites_inactive.png';
import List from "../list";

const MESSAGES = [
  "To get best recognition, increase the luminosity of your source.",
  "Be sure that the focus is correct, avoiding small or blurry text.",
  "For better results, target tasks with their counter within red square.",
];

function Display({ selected, setSelected, counts, setCounts, favorites, setFavorites, locale, orientation }) {
  const [tab, setTab] = useState(0);
  const [displayCamera, setDisplayCamera] = useState(false);
  const [processing, setProcessing] = useState(0);
  const [newValues, setNewValues] = useState(null);
  const [error, setError] = useState(null);
  const [possibilities, setPossibilities] = useState(null);

  const onNewValues = (pkmn, tasks) => {
    setSelected(pkmn.name.en);
    setTab(1);
    setTimeout(() => setNewValues(tasks), 1);
  };

  const processImage = (data) => {
    setDisplayCamera(false);
    setProcessing(0.01);

    parsePicture(data, onNewValues, setError, setProcessing, (possibilities, tasks) => {
      setPossibilities({ possibilities, tasks });
    });
  }

  useEffect(() => {
    setNewValues(null);
  }, [selected]);

  useEffect(() => {
    if(error) {
      setTimeout(() => setError(null), 5000);
    }
  }, [error, setError]);

  const acceptValues = () => {
    const newCounts = Object.assign({}, counts);
    newCounts[selected.name.en] = newValues.map(v => v.value);
    setCounts(newCounts);
    setNewValues(null);
  };

  const rejectValues = () => {
    setNewValues(null);
  };

  const renderTab = (i, imgActive, imgInactive, onClick) => {
    return (
      <div className={"Display-tab" + (tab === i ? " Display-tab-selected" : "")} onClick={onClick || (() => setTab(i))} style={{ zIndex: (4 - Math.abs(tab - i)) }}>
        <img src={tab === i ? imgActive : imgInactive} alt="" />
      </div>
    );
  };

  const selectInfos = (name) => {
    setSelected(name);
    setTab(0);
  };

  return (
    <div className="Display">
      <div className="Display-selector">
        {renderTab(0, infosActive, infosInactive)}
        {renderTab(1, tasksActive, tasksInactive)}
        {renderTab(2, mapActive, mapInactive)}
        {renderTab(3, cameraInactive, cameraInactive, () => setDisplayCamera(true))}
        {renderTab(4, favoritesActive, favoritesInactive)}
      </div>
      <div className="Display-inner">
        {tab === 0 && <Infos selected={selected} counts={counts} setCounts={setCounts} favorites={favorites} setFavorites={setFavorites} locale={locale} />}
        {tab === 1 && <Tasks selected={selected} newValues={newValues} counts={counts} setCounts={setCounts} acceptValues={acceptValues} rejectValues={rejectValues} locale={locale} />}
        {tab === 2 && <Locations selected={selected} locale={locale} />}
        {tab === 4 && <Favorites counts={counts} setCounts={setCounts} favorites={favorites} setFavorites={setFavorites} selectInfos={selectInfos} locale={locale} />}
      </div>
      {/* TODO : Pass components as children to Camera for overlay design (message : For better recognition, adjust luminosity of Switch / For better results, scan tasks with count only in red square / ... Rotating (fade)) */}
      {displayCamera && (
        <Camera onPhotoShoot={processImage} portrait={orientation}>
          <div className="Display-camera-target">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="Display-camera-message">
            <RotatingMessage className="Display-camera-message-text" messages={MESSAGES} locale={locale} />
          </div>
        </Camera>
      )}
      {!!processing && (/* TODO : Portalize that ? */
        <div className={"Display-processing" + (processing === 0.01 ? " Display-processing-infinite" : "")}>
          <div className="Display-processing-status">{translate(processing > 0.01 ? "Analysing" : "Loading", locale)}</div>
          <div className="Display-processing-bar">
            <div className="Display-processing-bar-inner" style={processing > 0.01 ? { width: (processing * 100) + "%" } : undefined} />
          </div>
        </div>
      )}
      {!!error && (
        <div className="Tasks-newvalues">
          <div className="Tasks-newvalues-label">
            {error.split("\n").map((l, i) => <div key={i}>{l}</div>)}
          </div>
        </div>
      )}
      {!!possibilities && (/* TODO : Portalize that ? */
        <div className="Display-possibilities" onClick={() => setPossibilities(null)}>
          <div className="Display-possibilities-inner">
            <div className="Display-possibilities-title">{translate("Select a Pokemon", locale)}</div>
            <div className="Display-possibilities-list" style={{ height: Math.min(possibilities.possibilities.length * 72 + 16, Math.min(720, window.innerHeight) * 0.8)}}>
              <List selectionMode list={possibilities.possibilities} setSelected={(name) => onNewValues(possibilities.possibilities.find(pkmn => pkmn.name.en === name), possibilities.tasks)} counts={counts} favorites={favorites} locale={locale} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Display;
