import React, { useState, useEffect, Fragment } from 'react';
import Presentation from './presentation';
import Dex from './dex';

function MobileDex({ counts, setCounts, favorites, setFavorites, locale }) {
  const [showPresentation, setShowPresentation] = useState(true);
  const [orientation, setOrientation] = useState(window.screen.availHeight > window.screen.availWidth);

  useEffect(() => {
    const onChange = () => {
      setOrientation(window.screen.availHeight > window.screen.availWidth);
    };

    window.screen.orientation.addEventListener('change', onChange);

    return () => {
      window.screen.orientation.removeEventListener('change', onChange);
    };
  }, [setOrientation]);

  return (
    <Fragment>
      {showPresentation && <Presentation onClick={() => setShowPresentation(false)} counts={counts} locale={locale} orientation={orientation} />}
      {!showPresentation && <Dex onClick={() => setShowPresentation(true)} counts={counts} setCounts={setCounts} favorites={favorites} setFavorites={setFavorites} locale={locale} orientation={orientation} />}
    </Fragment>
  );
}

export default MobileDex;
