import { useState, Fragment } from 'react';
import data from '../../data.json';
import Display from './components/display';
import List from './components/list';
import bulletList from './components/display/images/bullet_list.png';

import './index.css';

function Dex({ onClick, counts, setCounts, favorites, setFavorites, locale, orientation }) {
  const [selected, setSelected] = useState(data[0].name.en);
  const [menuOpen, setMenuOpen] = useState(false);

  const selectedPkmn = data.find(p => p.name.en === selected);

  // TODO : Configure this
  const menuList = window.innerWidth < 1024;

  return (
    <div className={"Dex" + (orientation ? " Dex-portrait" : " Dex-landscape")}>
      <Display selected={selectedPkmn} setSelected={setSelected} counts={counts} setCounts={setCounts} favorites={favorites} setFavorites={setFavorites} locale={locale} orientation={orientation} />
      {menuList && (
        <Fragment>
          <div className="Dex-list-button" onClick={() => setMenuOpen(!menuOpen)}>
            <img src={bulletList} alt={""} />
          </div>
          <div className={"Dex-list-menu" + (menuOpen ? " Dex-list-menu-open" : " Dex-list-menu-closed")} onClick={() => setMenuOpen(!menuOpen)}>
            <div className="Dex-list-menu-inner">
              <List selected={selectedPkmn} list={data} setSelected={setSelected} counts={counts} setCounts={setCounts} favorites={favorites} setFavorites={setFavorites} locale={locale} />
            </div>
          </div>
        </Fragment>
      )}
      {!menuList && (
        <div className="Dex-list-inline">
          <List selected={selectedPkmn} list={data} setSelected={setSelected} counts={counts} setCounts={setCounts} favorites={favorites} setFavorites={setFavorites} locale={locale} />
        </div>
      )}
    </div>
  );
}

export default Dex;
