import Tesseract from "tesseract.js";
import pokedex from "../data.json";
import TYPES from '../legends/types.json';
import ATTACKS from '../legends/attacks.json';

const SEND_DEBUG = process.env.NODE_ENV === "production";

export default function parsePicture(data, onSuccess, onError, onProgress, onTie) {
  if(!data.name) {
    // The Tesseract lib needs a name on the blob
    data.name = "image.png";
  }

  Tesseract.recognize(
    data,
    'eng+fra',
    {
      logger: m => {
        //console.log(m);

        if(m.status === "recognizing text") {
          onProgress && onProgress(m.progress || 0.01);
        }
      },
      //workerPath: 'https://unpkg.com/tesseract.js@v2.0.0/dist/worker.min.js',
      //langPath: 'https://tessdata.projectnaptha.com/4.0.0',
      //corePath: 'https://unpkg.com/tesseract.js-core@v2.0.0/tesseract-core.wasm.js',
    }
  ).then(({ data: { text } }) => {
    if(!SEND_DEBUG) {
      console.log('Result', text);
    }

    onProgress && onProgress(1);

    // TODO : Find good pokemon
    let pkmn = null;
    const nameLines = text.split("\n").filter(l => l.match(/(Tasks?|T[aâà]ches?)/i) && l.match(/(Research|Pok[eèé]dex)/i));
    if(nameLines.length) {
      // TODO : Improve this (more robust with test checks)
      pkmn = nameLines.reduce((res, line) => res || line.split(" ").reduce((r, w) => r || pokedex.find(p => Object.values(p.name).find(n => n.toLowerCase() === w.toLowerCase())), null), null);
    }
    // Tasks parsing
    //const tasksLines = text.split("\n").map(l => l.match(/^([A-Za-zÀ-ÖØ-öø-ÿ’'"]+ ([A-Za-zÀ-ÖØ-öø-ÿ’'"]+ )+)([oO0-9]+).* .*\d+/i)).filter(l => !!l);
    const tasksLines = text.split("\n").map(l => l.match(/^(..? +)?([A-Za-zÀ-ÖØ-öø-ÿ’‘'"-]+ ([A-Za-zÀ-ÖØ-öø-ÿ’‘'"-]+ )+?)([0-9]+|[oO°!]( |$))/)).filter(l => !!l);
    const tasks = tasksLines.map(l => {
      // Value
      let value = parseInt(l[4].replace(/[oO°]/g, "0").replace(/[!]/g, "1"), 10);
      if(value > 999) {
        // Sometimes the first values are stuck, so let's decrease that
        // TODO : The 100 factor is empiric, will need to see how to adjust that
        value = Math.floor(value / 100);
      }

      const text = l[2];
      const task = {
        type: "QUEST",
        text,
        value,
      };

      if(text.match(/Number you've caught without being spotted/) || text.match(/sans +.*r[eèé]p[eèé]r[eèé]nt/i)) {
        task.type = "UNSPOTTED";
      } else if(text.match(/Number you've caught while they were in the air/) || text.match(/en +plein +vo[li]/i)) {
        task.type = "IN_THE_AIR";
      } else if(text.match(/Number you've caught while they were sleeping/) || text.match(/leur +sommei[li]/i)) {
        task.type = "SLEEPING";
      } else if(text.match(/Number caught at night/) || text.match(/la +nuit/i)) {
        task.type = "NIGHT";
      } else if(text.match(/Number caught at day/) || text.match(/la +journ[eèé][eèé]/i)) {
        task.type = "DAY";
      } else if(text.match(/Number caught/i) || text.match(/Sp[eèé]cimens? +attrap[eèé]s?/i)) {
        task.type = "CATCH";
      } else if(text.match(/use a strong style move/) || text.match(/Style +Puissant/i)) {
        task.type = "STRONG";
      } else if(text.match(/use an agile style move/) || text.match(/Style +Rapide/i)) {
        task.type = "AGILE";
      } else if(text.match(/^Times you have seen it use /i)) {
        task.type = "USE";
        task.attack = text.substring("Times you have seen it use ".length);
      } else if(text.match(/esp[aeèé]ce a utilis[eèé] /i)) {
        task.type = "USE";
        task.attack = text.replace(/^.*esp[aeèé]ce a utilis[eèé] /i, "").trim();
        const attack = new RegExp(task.attack.toLowerCase().replace(/[eèé]/g, "[eèé]").replace(/[li]/g, "[li]").replace(/[aâà]/g, "[aâà]").replace(/[’‘'"]/g, "[’‘'\"]"), "i");
        Object.keys(ATTACKS).forEach(a => {
          if(attack.exec(a) || Object.values(ATTACKS[a]).reduce((res, l) => res || attack.exec(l), false)) {
            task.attack = a;
          }
        });
      } else if(text.match(/Number you've evolved/) || text.match(/Sp[eèé]cimens? +.*[eèé]volu[eèé][rs]?/i)) {
        task.type = "EVOLUTION";
      } else if(text.match(/heavy specimen/) || text.match(/poids? +.*lourds?/i)) {
        task.type = "HEAVY";
      } else if(text.match(/light specimen/) || text.match(/poids? +.*plum[eèé]s?/i)) {
        task.type = "LIGHT";
      } else if(text.match(/small specimen/) || text.match(/p[eèé]tit[eèé] +.*taill[eèé]/i)) {
        task.type = "SMALL";
      } else if(text.match(/large specimen/) || text.match(/grand[eèé] +.*taill[eèé]/i)) {
        task.type = "LARGE";
      } else if(text.match(/^Number of you[’‘'"]ve defeated with /i)) {
        task.type = "WEAKNESS";
        task.weakness = text.substring(text.indexOf("with") + 5).replace("-type moves", "").trim();
      } else if(text.match(/^Sp[eèé]cimens? +vaincus?.*capacit[eèé] .*type /i)) {
        task.type = "WEAKNESS";
        // Try to map value to real type code
        task.weakness = text.substring(text.indexOf("type") + 5).trim();
        const weakness = new RegExp(task.weakness.toLowerCase().replace(/[eèé]/g, "[eèé]").replace(/[li]/g, "[li]").replace(/[aâà]/g, "[aâà]").replace(/[’‘'"]/g, "[’‘'\"]"), "i");
        Object.keys(TYPES).forEach(t => {
          if(weakness.exec(t) || Object.values(TYPES[t]).reduce((res, l) => res || weakness.exec(l), false)) {
            task.weakness = t;
          }
        });
      } else if(text.match(/Number defeated/i) || text.match(/Sp[eèé]cimens? +vaincus?/i)) {
        task.type = "DEFEATED";
      } else if(text.match(/Number of different forms you've obtained/)) {
        task.type = "FORMS";
      } else if(text.match(/Number of its forms you've registered/)) {
        task.type = "FORMS";
      } else if(text.match(/Apparences? diff[eèé]rentes?/i)) {
        task.type = "FORMS";
      } else if(text.match(/Number of alpha specimens caught/) || text.match(/Barons?/i)) {
        task.type = "ALPHAS";
      } else if(text.match(/Times you've given it food/) || text.match(/nourritur[eèé]/i)) {
        task.type = "FOOD";
      } else if(text.match(/Times you've scared it off with a Scatter Bang/) || text.match(/Sachet.*P[eèé]tarad[eèé]/i)) {
        task.type = "SCATTERBANG";
      } else if(text.match(/Number you've seen leap out of trees/) || text.match(/un +arbr[eèé]/i)) {
        task.type = "IN_TREES";
      } else if(text.match(/Number you've seen leap out of ore deposits/) || text.match(/gisement +minier?/i)) {
        task.type = "IN_ORES";
      } else if(text.match(/Times you've stunned it by using items/) || text.match(/ral[eèé]nti/i)) {
        task.type = "STUNNED";
      }  else {
        console.info("%cUnknown task type : " + text, "background-color: #5ED9FF; padding: 2px 4px; border: 1px solid #5196DB");
      }

      return task;
    }).filter((t, i, tab) => t.type !== "QUEST" || i === tab.length - 1);

    if(!SEND_DEBUG) {
      console.log("Pkmn :", pkmn, tasks);
    }

    // If not found, lookup by tasks
    if(!pkmn && tasks.length) {
      const tasksId = tasks.map(t => t.type).join(",");
      let possibilities = pokedex.filter(p => p.tasks.map(t => t.type).join(",") === tasksId);
      if(possibilities.length) {
        if(possibilities.length > 1) {
          // Try to drill down (with attacks, types, proximity in names...)
          //  -> Create a score based on similarity, then sort higher first
          possibilities = possibilities.map(pkmn => ({
            ...pkmn,
            // TODO : Improve this by managing similarity (instead of strict equality) in attack names / legends (here, or on the mapping code above)
            score: pkmn.tasks.reduce((s, t, i) => s + (t.attack === tasks[i].attack ? 10 : 0) + (t.weakness === tasks[i].weakness ? 10 : 0), 0),
          }));
          const maxScore = possibilities.reduce((res, pkmn) => res > pkmn.score ? res : pkmn.score, 0);
          possibilities = possibilities.filter(pkmn => pkmn.score === maxScore);

          if(!SEND_DEBUG) {
            console.log(possibilities);
          }

          // Some pokemons have the same task list...
          // If it's the case (score > 0), take all the highest scores and show a selection dialog
          if(maxScore > 0 && possibilities.length > 1 && onTie) {
            onTie(possibilities, tasks);
            onProgress && onProgress(0);
            return;
          }
        }

        pkmn = possibilities[0];
      }
    }

    if(pkmn && pkmn.tasks.length === tasks.length) {
      onSuccess(pkmn, tasks);
    } else {
      // Display an error message
      onError && onError("Impossible de détecter les valeurs.\nVeuillez réessayer.");

      // Debug mode
      if(SEND_DEBUG) {
        const formData = new FormData();
        formData.append("picture", data);
        formData.append("text", text);
        formData.append("tasks", JSON.stringify(tasks, null, 4));
        formData.append("pkmn", JSON.stringify(pkmn, null, 4));
        fetch("/debug.php", { method: 'POST', body: formData, mode: "no-cors" });
      }
    }

    onProgress && onProgress(0);
  });
}
