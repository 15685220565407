import { useState, useMemo, Fragment } from 'react';
import Scroller from 'react-custom-scroller';
import isCaught from '../../../../helpers/is-caught';
import dexScore from '../../../../helpers/dex-score';
import maxScore from '../../../../helpers/max-score';
import imageUrl from '../../../../helpers/image-url';
import Portal from "../../../../helpers/portal";
import translate from '../../../../legends';
import TYPES from '../../../../legends/types.json';
import LOCATIONS from '../../../../legends/locations.json';
//import TASKS_SHORT from '../../../../legends/tasks-short.json';

import './index.css';
import { ReactComponent as FilterIcon } from "./images/filter_list_black_24dp.svg";
import { ReactComponent as SortIcon } from "./images/sort_black_24dp.svg";
import { ReactComponent as ExpandIcon } from "./images/expand_more_black_24dp.svg";

const SORTS = {
  "no": "Number",
  "name": "Name",
  "score": "Completion",
};

const FILTERS = [{
  id: "type",
  getValues: (pkmn) => pkmn.types || [],
  values: ["Normal", "Fire", "Water", "Grass", "Flying", "Fighting", "Poison", "Electric", "Ground", "Rock", "Psychic", "Ice", "Bug", "Ghost", "Steel", "Dragon", "Dark", "Fairy"],
  getLegend: (v, locale) => TYPES[v][locale] || v,
}, {
  id: "completion",
  getValues: (pkmn) => [pkmn.score < 10 ? "uncompleted" : (pkmn.score === pkmn.maxScore ? "perfect" : "completed")],
  values: ["uncompleted", "completed", "perfect"],
}, {
  id: "progress",
  getValues: (pkmn) => ["" + Math.floor(pkmn.progress / 10)],
  values: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
}, {
  id: "location",
  getValues: (pkmn) => Object.keys(pkmn.locations || {}),
  values: ["FIELDLANDS", "MIRELANDS", "COASTLANDS", "HIGHLANDS", "ICELANDS"],
  getLegend: (v, locale) => LOCATIONS[v][locale] || v,
}, {
  id: "weakness",
  getValues: (pkmn) => (pkmn.tasks || []).filter(t => t.type === "WEAKNESS").map(t => t.weakness),
  values: ["Normal", "Fire", "Water", "Grass", "Flying", "Fighting", "Poison", "Electric", "Ground", "Rock", "Psychic", "Ice", "Bug", "Ghost", "Steel", "Dragon", "Dark", "Fairy"],
  getLegend: (v, locale) => TYPES[v][locale] || v,
},/* {
  id: "task_type",
  getValues: (pkmn) => (pkmn.tasks || []).map(t => t.type),
  values: Object.keys(TASKS_SHORT),
  getLegend: (v, locale) => (TASKS_SHORT[v][locale] || v).replace(/\{attack\}/g, translate("Attack", locale)).replace(/\{weakness\}/g, translate("Weakness", locale)).replace(/\{quest_no\}/g, ""),
}*/];

const stopPropagation = (e) => e.stopPropagation();

function Filters({ sort, setSort, filters, setFilters, locale }) {
  const [filterMenu, setFilterMenu] = useState(false);
  const [closedFilter, setClosedFilters] = useState(FILTERS.filter(f => f.closed).map(f => f.id));

  const setNextSort = () => {
    const sorts = Object.keys(SORTS);
    setSort(sorts[(sorts.findIndex(s => s === sort) + 1) % sorts.length]);
  };

  const openFilterMenu = () => {
    setFilterMenu(true);
  };

  const closeFilterMenu = () => {
    setFilterMenu(false);
  };

  const toggleClosedFilter = (id) => {
    if(closedFilter.includes(id)) {
      setClosedFilters(closedFilter.filter(f => f !== id));
    } else {
      setClosedFilters(closedFilter.concat([id]));
    }
    // Patch for Scroller size detection with height transition
    [100, 200, 300, 350, 400].forEach(t => setTimeout(() => window.dispatchEvent(new Event('resize')), t));
  }

  return (
    <div className="List-filters" onClick={(e) => e.stopPropagation()}>
      <div className="List-filters-button" onClick={setNextSort}><SortIcon width="32px" height="32px" fill="#FFFFFF" /> <span>{translate(SORTS[sort], locale)}</span></div>
      <div className="List-filters-button" onClick={openFilterMenu}><FilterIcon width="32px" height="32px" fill="#FFFFFF" /></div>
      {filterMenu && (
        <Portal parentId="App">
          <div className="List-filtersmenu" onClick={closeFilterMenu}>
            <div className="List-filtersmenu-inner" onClick={stopPropagation}>
              <div className="List-filtersmenu-title">{translate("Select filters to apply", locale)}</div>
              <Scroller className="List-filtersmenu-list">
                {FILTERS.map(filter => {
                  const applied = filters[filter.id] || [];
                  const toggleValue = (option) => {
                    if(applied.includes(option)) {
                      setFilters({ ...filters, [filter.id]: applied.filter(a => a !== option) });
                    } else {
                      setFilters({ ...filters, [filter.id]: applied.concat([option]) });
                    }
                  };

                  return (
                    <div key={filter.id} className={"List-filtersmenu-filter" + (closedFilter.includes(filter.id) ? " List-filtersmenu-filter-closed" : "")}>
                      <div className="List-filtersmenu-filter-header" onClick={() => toggleClosedFilter(filter.id)}>
                        <div className="List-filtersmenu-filter-title">{translate("filter_" + filter.id, locale)}</div>
                        <ExpandIcon className="List-filtersmenu-filter-expand"/>
                      </div>
                      <div className="List-filtersmenu-filter-options">
                        {filter.values.map(option => <div key={option} className={"List-filtersmenu-filter-option" + (applied.includes(option) ? " List-filtersmenu-filter-option-enabled" : "")} onClick={() => toggleValue(option)}>{filter.getLegend ? filter.getLegend(option, locale) : translate(filter.id + "_" + option, locale)}</div>)}
                      </div>
                    </div>
                  );
                })}
              </Scroller>
              <div className="List-filtersmenu-buttons">
                <div className="List-filtersmenu-button" onClick={closeFilterMenu}>{translate("Apply", locale)}</div>
                <div className="List-filtersmenu-button List-filtersmenu-button-reset" onClick={() => setFilters({})}>{translate("Reset", locale)}</div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
}


function List({ selected, setSelected, list, counts, locale, selectionMode }) {
  const [sort, setSort] = useState("no");
  const [filters, setFilters] = useState({});

  const sortedAndFilteredList = useMemo(() => {
    const l = (list || []).map(pkmn => {
      const score = dexScore(pkmn, counts);
      const max = maxScore(pkmn);
      return { ...pkmn, score: score, maxScore: max, progress: max > 0 ? score * 100 / max : 0 };
    }).filter(pkmn => sort !== "score" || !!pkmn.maxScore).filter(pkmn => {
      return Object.keys(filters).reduce((res, filterId) => {
        if(!res) {
          return false;
        }

        if(!filters[filterId].length) {
          return true;
        }

        const filter = FILTERS.find(f => f.id === filterId);
        const v = filter.getValues(pkmn);
        const values = Array.isArray(v) ? v : [v];

        return !!values.find(value => filters[filterId].includes(value));
      }, true);
    });
    l.sort((a, b) => {
      if(sort === "name") {
        return (a.name[locale] || a.name.en).localeCompare(b.name[locale] || b.name.en);
      } else if(sort === "score") {
        return b.progress - a.progress;
      }

      return a.no - b.no;
    });

    return l;
  }, [list, counts, sort, filters, locale]);

  return (
    <div className="List">
      {!selectionMode && <Filters sort={sort} setSort={setSort} filters={filters} setFilters={setFilters} locale={locale} />}
      <Scroller className="List-list">
        {!sortedAndFilteredList.length && (
          <div className="List-noentry">{translate("Nothing to display", locale)}</div>
        )}
        {sortedAndFilteredList.map(r => (
          <div key={r.name.en} className={"List-entry" + (isCaught(r, counts) ? "" : " List-entry-uncaught") + (selected.name.en === r.name.en ? " List-entry-selected" : "") + (selectionMode ? " List-entry-selectionmode" : "")} onClick={() => setSelected(r.name.en)}>
            {!!r.images.length && <div className="List-entry-thumb" style={{ backgroundImage: "url(\"" + imageUrl(r, r.images[0]) + "\")" }}></div>}
            <div className="List-entry-text">
              {sort === "no" && (
                <Fragment>
                  <div>No. {(r.no < 100 ? "0" : "") + (r.no < 10 ? "0" : "") + r.no}</div>
                  <div className="List-entry-name List-entry-subtext">{r.name[locale] || r.name.en}</div>
                </Fragment>
              )}
              {sort === "name" && (
                <Fragment>
                  <div className="List-entry-name">{r.name[locale] || r.name.en}</div>
                  <div className="List-entry-subtext">No. {(r.no < 100 ? "0" : "") + (r.no < 10 ? "0" : "") + r.no}</div>
                </Fragment>
              )}
              {sort === "score" && (
                <Fragment>
                  <div className="List-entry-name">{r.name[locale] || r.name.en}</div>
                  <div className="List-entry-subtext">{("" + r.progress.toFixed(2)).replace(/[.,]?0+$/, "")}% ({r.score}/{r.maxScore})</div>
                </Fragment>
              )}
            </div>
            {r.score > 9 && <div className={"List-entry-captured" + (r.score === r.maxScore ? " List-entry-captured-perfect" : "")}></div>}
          </div>
        ))}
      </Scroller>
    </div>
  );
}

export default List;
