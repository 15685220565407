import data from '../../../../data.json';
import translate from "../../../../legends";
import TASKS_SHORT from "../../../../legends/tasks-short.json";
import ATTACKS_TEXTS from "../../../../legends/attacks.json";
import TYPES_TEXTS from "../../../../legends/types.json";
import QUEST_TEXTS from "../../../../legends/quests.json";
import favoritesInactive from './images/favorites_inactive.png';
import dexScore from "../../../../helpers/dex-score";
import maxScore from "../../../../helpers/max-score";

const COLORS = [
  "#FF482D",
  "#F49025",
  "#F8C22D",
  "#64C172",
  "#319E43",
];

function FavoriteTask({ type, attack, weakness, quest, count, targets, incrementCount, locale }) {
  const nextTarget = targets.find(t => t > count);
  const targetsLeft = targets.filter(t => t > count).length - 1;

  const progressColor = targets[targets.length - 1] > 1 ? COLORS[Math.floor(count * (COLORS.length - 1) / Math.max((targets[targets.length - 1] - 1), 1))] : COLORS[COLORS.length - 1];

  const taskName = ((TASKS_SHORT[type] && TASKS_SHORT[type][locale]) || "")
    .replace(/\{attack\}/g, (ATTACKS_TEXTS[attack] && ATTACKS_TEXTS[attack][locale]) || attack)
    .replace(/\{weakness\}/g, (TYPES_TEXTS[weakness] && TYPES_TEXTS[weakness][locale]) || weakness)
    .replace(/\{quest\}/g, (QUEST_TEXTS[quest] && (QUEST_TEXTS[quest][locale] || QUEST_TEXTS[quest].en)) || quest)
    .replace(/\{quest_no\}/g, quest);

  return (
    <div className="Favorites-quicktask">
      <div className="Favorites-quicktask-name">
        {taskName}
      </div>
      <div className="Favorites-quicktask-value" onClick={incrementCount} style={{ color: progressColor }}>{count}/{nextTarget}{targetsLeft > 0 && <sup>+{targetsLeft}</sup>}</div>
    </div>
  );
}

function Favorite({ favorite, removeFavorite, counts, setCounts, selectInfos, locale }) {
  const favoriteCounts = (counts[favorite.name.en] || []);
  const tasksLeft = favorite.tasks.map((t, i) => ({ ...t, count: favoriteCounts[i] || 0, key: i })).filter((t, i) => !favoriteCounts[i] || favoriteCounts[i] < t.targets[t.targets.length - 1]);

  const incrementClick = (id) => {
    const newCounts = Object.assign({}, counts);
    newCounts[favorite.name.en] = (counts[favorite.name.en] || favorite.tasks.map(_ => 0)).map((x, i) => i === id ? Math.min(x + 1, 999) : x);
    setCounts(newCounts);
  };

  const onStarClick = (e) => {
    e.stopPropagation();
    removeFavorite();
  }

  return (
    <div className="Favorites-favorite">
      <div className="Favorites-favorite-title" onClick={() => selectInfos(favorite.name.en)}>
        <div className="Favorites-star" onClick={onStarClick}></div>
        <div className="Favorites-name">{favorite.name[locale] || favorite.name["en"]}</div>
        <div className={"Favorites-completion" + (dexScore(favorite, counts) > 9 ? " Favorites-completed" : "") + (dexScore(favorite, counts) === maxScore(favorite) ? " Favorites-completed-perfect" : "")}></div>
      </div>
      <div className="Favorites-favorite-result">
        {!!tasksLeft.length && (
          <div className="Favorites-favorite-quicktasks">
            {tasksLeft.map((t, i) => <FavoriteTask {...t} incrementCount={() => incrementClick(t.key)} locale={locale} />)}
          </div>
        )}
        {!tasksLeft.length && (
          <div>
            Recherches complétées !
          </div>
        )}
      </div>
    </div>
  );
}

function Favorites({ favorites, setFavorites, counts, setCounts, selectInfos, locale }) {
  const removeFavorite = (id) => {
    setFavorites(favorites.filter(f => f !== id));
  }

  return (
    <div className="Favorites">
      {!favorites.length && (
        <div className="Favorites-nofavorite">
          <div>{translate("No favorite added.", locale)}</div>
          <div>{translate("Click on the {Star} icon on a Pokemon's information to add it here.", locale).split(/[{}]/).map((t, i) => {
            if(i%2 === 1) {
              return <img key={i} src={favoritesInactive} alt={t} title={t} />;
            } else if(t) {
              return <span key={i}>{t}</span>;
            }

            return null;
          })}</div>
        </div>
      )}
      <div className="Favorites-favorites">
        {favorites.map(name => <Favorite key={name} favorite={data.find(p => p.name.en === name)} removeFavorite={() => removeFavorite(name)} counts={counts} setCounts={setCounts} selectInfos={selectInfos} locale={locale} />)}
      </div>
    </div>
  );
}

export default Favorites;
