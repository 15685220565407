import { useEffect, useState } from 'react';
import { createPortal } from "react-dom";

export default function Portal({ children, parentId, className }) {
  const [div, setDiv] = useState(null);

  useEffect(() => {
    if(!div) {
      // Init div for portal
      const d = document.createElement('div');
      if (className) {
        d.className = className;
      }
      ((parentId && document.getElementById(parentId)) || document.body).appendChild(d);
      setDiv(d);
    } else {
      // Set the className
      div.className = className || '';
      // parentId change
      if((parentId && document.getElementById(parentId) !== div.parentNode) || (!parentId && document.body !== div.parentNode)) {
        ((parentId && document.getElementById(parentId)) || document.body).appendChild(div);
      }
    }

    return () => {
      if(div) {
        div.parentNode.removeChild(div);
      }
    };
  }, [div, className, parentId]);

  return div ? createPortal(children, div) : null;
}