import { useState, useEffect } from 'react';
import translate from '../legends';


function RotatingMessage({ messages, className, locale, messageDuration = 4000, transitionDuration = 400 }) {
  const [currentMessage, setCurrentMessage] = useState(1);

  useEffect(() => {
    // Start timer
    setCurrentMessage(1);
    let timeout;
    const timeNextMessage = () => {
      // Hide message
      timeout = setTimeout(() => {
        setCurrentMessage((c) => -c);
        // Show next message after transition
        timeout = setTimeout(() => {
          setCurrentMessage((c) => (Math.abs(c) % messages.length) + 1);
          timeNextMessage();
        }, transitionDuration);
      }, messageDuration);
    }
    timeNextMessage();

    return () => {
      clearTimeout(timeout);
    };
  }, [messages, messageDuration, transitionDuration]);

  return (
    <div className={className} style={{ opacity: currentMessage > 0 ? 1 : 0 }}>{translate(messages[Math.abs(currentMessage) - 1], locale)}</div>
  );
}

export default RotatingMessage;
