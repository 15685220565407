import './index.css';

import { useState } from 'react';

import { ReactComponent as UpdateIcon } from "./images/update_black_24dp.svg";
import { ReactComponent as TaskAltIcon } from "./images/task_alt_black_24dp.svg";

function Update({ updateAvailable, onUpdate }) {
  const [showUpdate, setShowUpdate] = useState(true);
  const [updated, setUpdated] = useState((window.localStorage && window.localStorage.getItem("updated")) || false);

  if(showUpdate && !!onUpdate) {
    return (
      <div className="Update" onClick={() => setShowUpdate(false)}>
        <div className="Update-dialog" onClick={(e) => { e.stopPropagation(); onUpdate(); }}>
          <div className="Update-dialog-icon">
            <UpdateIcon width="64px" height="64px" fill="#FFFFFF" />
          </div>
          <div>Une mise à jour de l'application {!!updateAvailable ? "(v" + updateAvailable + ")" : ""} est prête à être installée.</div>
          <div style={{ marginTop: 8 }}>Cliquez-ici pour lancer la mise à jour</div>
        </div>
      </div>
    );
  }

  if(updated) {
    return (
      <div className="Update" onClick={() => { window.localStorage && window.localStorage.removeItem("updated"); setUpdated(false); }}>
        <div className="Update-dialog">
          <div className="Update-dialog-icon">
            <TaskAltIcon width="64px" height="64px" fill="#FFFFFF" />
          </div>
          <div>La mise à jour de l'application est complétée !</div>
        </div>
      </div>
    );
  }

  return null;
}

export default Update;
