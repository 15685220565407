import data from '../../data.json';
import isCaught from '../../helpers/is-caught';
import isRegistered from '../../helpers/is-registered';
import dexScore from '../../helpers/dex-score';
import doneTasks from '../../helpers/done-tasks';
import maxTasks from '../../helpers/max-tasks';
import translate from '../../legends';
import LOCATIONS from '../../legends/locations.json';
import LEVELS from '../../legends/levels.json';

import './index.css';
import titleImage from './images/title.png';

function Presentation({ onClick, counts, locale, orientation }) {
  const countRegistered = (zone) => data.filter(pkmn => (!zone || pkmn.locations[zone]) && isRegistered(pkmn, counts)).length;
  const countCaught = (zone) => data.filter(pkmn => (!zone || pkmn.locations[zone]) && isCaught(pkmn, counts)).length;

  const renderZone = (zone) => {
    return (
      <div className="Presentation-zone">
        <div className="Presentation-zone-title">{LOCATIONS[zone][locale] || LOCATIONS[zone]["en"]}</div>
        <div className="Presentation-zone-result">V {countRegistered(zone)} C {countCaught(zone)}</div>
      </div>
    );
  }

  const points = data.reduce((res, pkmn) => res + dexScore(pkmn, counts) * 10 + (dexScore(pkmn, counts) > 9 ? 100 : 0), 0);
  const level = LEVELS.filter(l => l.score <= points).pop();

  return (
    <div className={"Presentation" + (orientation ? " Presentation-portrait" : " Presentation-landscape")} onClick={onClick}>
      <div className="Presentation-title">
        <img src={titleImage} alt="" />
      </div>
      <div className="Presentation-results">
        <div className="Presentation-result">
          <div className="Presentation-result-title">{countRegistered()} {translate("Registered", locale)}</div>
        </div>
        <div className="Presentation-result">
          <div className="Presentation-result-title">{countCaught()} {translate("Caught", locale)}</div>
        </div>
      </div>
      <div className="Presentation-zones">
        {renderZone("FIELDLANDS")}
        {renderZone("MIRELANDS")}
        {renderZone("COASTLANDS")}
        {renderZone("HIGHLANDS")}
        {renderZone("ICELANDS")}
      </div>
      <div className="Presentation-progression">
        <div>{translate("Completed", locale)} : {data.reduce((res, pkmn) => res + doneTasks(pkmn, counts), 0)} / {data.reduce((res, pkmn) => res + maxTasks(pkmn, counts), 0)}</div>
        <div>{translate("Points", locale)} : {points}</div>
        {level && <div>{level.title[locale] || level.title["en"]}</div>}
      </div>
      {/* TODO : Missing clips on corners */}
    </div>
  );
}

export default Presentation;
