import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import MobileDex from './mobile-dex';
import Update from './mobile-dex/update';

import './App.css';

function App({ updateAvailable, onUpdate }) {
  const [locale, setLocale] = useState("fr");
  const [counts, setCountsState] = useState(null);
  const [favorites, setFavoritesState] = useState(null);

  useEffect(() => {
    setCountsState(JSON.parse(window.localStorage.getItem("counts") || "{}"));
    setFavoritesState(JSON.parse(window.localStorage.getItem("favorites") || "[]"));
  }, []);

  const setCounts = useCallback((c) => {
    window.localStorage.setItem("counts", JSON.stringify(c));
    setCountsState(c);
  }, [setCountsState]);

  const setFavorites = useCallback((c) => {
    window.localStorage.setItem("favorites", JSON.stringify(c));
    setFavoritesState(c);
  }, [setFavoritesState]);

  if(!counts) {
    return null;
  }

  return (
    <div className="App" id="App">
      <MobileDex counts={counts} setCounts={setCounts} favorites={favorites} setFavorites={setFavorites} locale={locale} setLocale={setLocale} />
      <Update updateAvailable={updateAvailable} onUpdate={onUpdate} />
    </div>
  );
}

export default App;
