import { useRef } from 'react';
import Portal from "../../helpers/portal";

import './index.css';

function Camera({ onPhotoShoot, allowPictureUpload = true, portrait, children }) {
  const video = useRef(null);

  const videoMount = (c) => {
    if(video.current && video.current.srcObject) {
      // Stop the current stream
      video.current.srcObject.getTracks().forEach(function(track) {
        track.stop();
      });
    }

    if (c && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // TODO :
      //  In portrait :  video: { aspectRatio: 9/16 }
      //  In landscape :  video: { aspectRatio: 16/9 }
      //  Then lock screen orientation with ScreenOrientation.lock()
      navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: { ideal: 'environment' },
          width: { ideal: 1280 },
          height: { ideal: 720 },
        }
      }).then((stream) => {
        c.srcObject = stream;
        c.play();
      });
    }

    video.current = c;
  }

  const takePhoto = () => {
    if(video.current) {
      const size = video.current.getBoundingClientRect();
      const width = video.current.videoWidth || size.width;
      const height = video.current.videoHeight || size.height;

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      //document.body.appendChild(canvas);
      const context = canvas.getContext('2d');
      context.drawImage(video.current, 0, 0, width, height);

      canvas.toBlob((data) => onPhotoShoot(data));
    }
  }

  const uploadPicture = (e) => {
    if(e.target.files && e.target.files[0]) {
      onPhotoShoot(e.target.files[0]);
    }
  }

  return (
    <Portal className={"Camera" + (portrait ? " Camera-portrait" : " Camera-landscape")} parentId="App">
      <video className="Camera-video" disablePictureInPicture ref={videoMount} />
      {children && (
        <div className="Camera-overlay">
          {children}
        </div>
      )}
      <div className="Camera-button" onClick={takePhoto}></div>
      {allowPictureUpload && (
        <div className="Camera-upload">
          <span className="material-icons Camera-upload-icon">file_upload</span>
          <input className="Camera-upload-input" type="file" onChange={uploadPicture} accept="image/*" />
        </div>
      )}
    </Portal>
  );
}

export default Camera;
