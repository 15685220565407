export default function isCaught(pkmn, counts) {
  if(!pkmn.tasks || !pkmn.tasks.length) {
    return false;
  }

  const i = pkmn.tasks.findIndex(t => t.type === "CATCH");
  if(i < 0) {
    return false;
  }

  return !!(counts[pkmn.name.en] && counts[pkmn.name.en][i]);
};