import translate from "../../../../legends";
import LOCATIONS from "../../../../legends/locations.json";

function Location({ zone, locations, locale }) {
  return (
    <div className="Locations-zone">
      <div className="Locations-zone-title">{LOCATIONS[zone][locale] || LOCATIONS[zone]["en"]}</div>
      <div className="Locations-zone-result">
        {locations.map((l, i) => {
          return (
            <div key={i} className="Locations-zone-location">
              {LOCATIONS[l.location] ? (LOCATIONS[l.location][locale] || LOCATIONS[l.location].en) : l.location} ({l.method}/{l.rarity})
            </div>
          );
        })}
      </div>
    </div>
  );
}

function Locations({ selected, locale }) {
  return (
    <div className="Locations">
      <div className="Locations-header">
        <div className="Locations-name">{translate("Main locations for", locale)} {selected.name[locale] || selected.name.en}</div>
      </div>
      <div className="Locations-zones">
        {Object.keys(selected.locations).map(zone => <Location key={zone} zone={zone} locations={selected.locations[zone]} locale={locale} />)}
      </div>
    </div>
  );
}

export default Locations;
