import dexScore from '../../../../helpers/dex-score';
import maxScore from '../../../../helpers/max-score';
import imageUrl from '../../../../helpers/image-url';
import translate from '../../../../legends';
import TYPES from '../../../../legends/types.json';

function Infos({ selected, counts, favorites, setFavorites, locale }) {
  const toggleFavorite = () => {
    if(favorites.includes(selected.name.en)) {
      setFavorites(favorites.filter(f => f !== selected.name.en));
    } else {
      setFavorites(favorites.concat([selected.name.en]));
    }
  };

  return (
    <div className="Infos">
      <div className="Infos-header">
        <div className={"Infos-favorite" + (favorites.includes(selected.name.en) ? " Infos-favorited" : "")} onClick={toggleFavorite}></div>
        <div className="Infos-number">No. {(selected.no < 100 ? "0" : "") + (selected.no < 10 ? "0" : "") + selected.no}</div>
        <div className="Infos-name">{selected.name[locale] || selected.name.en}</div>
        <div className="Infos-types">
          {(selected.types || []).map(t => (
            <div key={t} className={"Infos-type Infos-type-" + t.toLowerCase()}>
              <img src={process.env.PUBLIC_URL + "/images/types/" + t.toLowerCase() + ".png"} alt={TYPES[t][locale] || TYPES[t].en || t} title={TYPES[t][locale] || TYPES[t].en || t} />
              <span>{TYPES[t][locale] || TYPES[t].en || t}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="Infos-center">
        <div className="Infos-picture">
          {!!selected.images.length && <div className="Infos-picture-thumb" style={{ backgroundImage: "url(\"" + imageUrl(selected, selected.images[0]) + "\")" }}></div>}
        </div>
        <div className="Infos-column">
          <div>{translate("Weight", locale)}</div>
          <div>{translate("Size", locale)}</div>
          <div>{translate("Food", locale)}</div>
          <div>{translate("Objects", locale)}</div>
        </div>
      </div>
      <div className="Infos-footer">
        <div className="Infos-description">
          {translate("Description", locale)}
        </div>
        <div className="Infos-level">
          {translate("Level", locale)}
          <div>{dexScore(selected, counts)} / {maxScore(selected)}</div>
        </div>
      </div>
    </div>
  );
}

export default Infos;
